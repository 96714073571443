import styled, { device } from "@styled";

export const SectionWrap = styled.section`
    position: relative;
    z-index: 1;
    padding:0 0 40px 0;
    ${device.small} {
        padding:40px 0 60px 0;
    }
    ${device.xlarge} {
        padding:60px 0 80px 0;
    }
`;
export const SolutionImgWrapper = styled.div`
    display: grid;
    gap:20px;
    justify-content:center;
    .solutionsImgCol{
        text-align:center;
        transition:all .5s ease-in-out;
        position:relative;
        &:nth-child(3){
            .textWrapper{
                bottom:30px;
            }
        }
    }
    ${device.medium} {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(3, auto);
        .solutionsImgCol{
            &:nth-child(3){
                grid-column: 2;
                grid-row: 1 / span 2;
            }
        }
    }
    ${device.large} {
        gap: 20px 15px;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, auto);
        .solutionsImgCol{
            &:nth-child(1){
                grid-column: 1;
                grid-row: 1;
            }
            &:nth-child(2){
                grid-column: 1;
                grid-row: 2;
                align-self:flex-end;
            }
            &:nth-child(3){
                grid-column: 2;
                grid-row: 1 / span 2;
                .textWrapper{
                    bottom:30px;
                }
            }
            &:nth-child(4){
                grid-column: 3;
                grid-row: 1;
            }
            &:nth-child(5){
                grid-column: 3;
                grid-row: 2;
                align-self:flex-end;
            }
            &:hover{
                transform:scale(.975);
            }
        }
    }
    ${device.xlarge} {
        gap:20px;
    }
    ${device.xxlarge} {
        gap: 22px 30px;
    }
`;
export const SolutionImgBox = styled.div`
    img{
        max-width:100%;
    }
`;
export const SolutionText = styled.div`
    position:absolute;
    bottom:35px;
    left:50%;
    transform:translateX(-50%);
    width:90%;
    ${device.xlarge} {
        bottom:50px;
    }
    h4{
        font-size: 20px;
        text-align:center;
        letter-spacing: 0px;
        line-height: 1.333;
        color: #ffffff;
        font-weight: 700;
        margin:0;
        ${device.xlarge} {
            font-size: 24px;
        }
    }
`;
