import React from "react"
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Anchor from "@ui/anchor";
import Image from "@ui/image";
import Heading from "@ui/heading";
import { SectionWrap, SolutionImgWrapper, SolutionImgBox, SolutionText} from "./style";
const solutionsImgBox = ({data}) => {
    return(
        <SectionWrap id={data.section}>
            <Container>
                <SectionTitle
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                />
                <SolutionImgWrapper>
                {data &&
                    data.items.map((el, index) => (
                    <Anchor path={el.path} key={index} title={el.title} className="solutionsImgCol">
                        <SolutionImgBox>
                            <Image
                                src={el.images[0]?.src}
                                alt={el.images[0]?.alt || "Image"}
                                title={el.images[0]?.alt || "Image"}
                            />
                        </SolutionImgBox>
                        <SolutionText className="textWrapper">
                            <Heading as="h4">{el.title}</Heading>
                        </SolutionText>
                    </Anchor>
                ))}
                </SolutionImgWrapper>
            </Container>
         </SectionWrap>
    );
};
solutionsImgBox.propTypes = {
    items: PropTypes.object,
};
export default solutionsImgBox;